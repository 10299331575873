import dynamic from 'next/dynamic';
import axios from 'axios';
import getConfig from 'next/config';
import { useEffect } from 'react';
import { setupIonicReact } from '@ionic/react';

const App = dynamic(() => import(`@/modules/layout/components/Layout`), {
  ssr: false,
});

export default function Index() {
  const { publicRuntimeConfig } = getConfig();
  axios.defaults.baseURL = `${publicRuntimeConfig.api_url}`;

  axios.defaults.headers.common.Accept = `application/json`;
  axios.defaults.headers.post[`Content-Type`] = `application/json`;

  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) =>
      // Edit response config
      response,
    (error) => Promise.reject(error),
  );

  useEffect(() => {
    setupIonicReact({
      mode: `md`,
    });
  }, []);
  return <App />;
}
